import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { Map, Track } from "@/features";

import {
    GetEventsApiArg,
    useGetDevicesByIdGpsQuery,
} from "@/shared/api/general.api";
import { useFilter } from "@/shared/lib/hooks";
import { ErrorBlock, Separator } from "@/shared/ui";

import { Filter } from "./filter";

export const GPS: React.FC = () => {
    const { id = "" } = useParams();
    const { filter, setFilter } = useFilter<GetEventsApiArg>();

    const { data, isError } = useGetDevicesByIdGpsQuery(
        {
            id,
            ...(filter ?? {}),
        },
        {
            skip: !id,
        },
    );

    const onSubmit = useCallback(
        (values: GetEventsApiArg) => {
            setFilter({ ...values, page: 1 });
        },
        [setFilter],
    );

    return (
        <>
            <Filter values={filter} onSubmit={onSubmit} />
            <Separator className="my-8" />
            {!data || isError ? (
                <ErrorBlock />
            ) : (
                <Map>
                    <Track data={data} withPlayer />
                </Map>
            )}
        </>
    );
};
