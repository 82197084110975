"use client";

import { TableOptions, flexRender, useReactTable } from "@tanstack/react-table";
import { ChevronLeft, ChevronRight } from "lucide-react";
import ReactPaginate from "react-paginate";

import { Loading } from "@/features";

import {
    Pagination,
    PaginationEllipsis,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/shared/ui";

interface DataTableProps<TData> extends TableOptions<TData> {
    isLoading?: boolean;
}

/* TODO: refactor: decompose this component into smaller components */
export function DataTable<TData>({
    isLoading = false,
    ...props
}: DataTableProps<TData>) {
    const table = useReactTable({
        ...props,
    });

    return (
        <div>
            <Table>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        key={header.id}
                                        colSpan={header.colSpan}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext(),
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody className="relative">
                    {isLoading ? (
                        <tr>
                            <td colSpan={table.getVisibleLeafColumns().length}>
                                <Loading />
                            </td>
                        </tr>
                    ) : table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow
                                key={row.id}
                                data-state={row.getIsSelected() && "selected"}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                colSpan={table.getVisibleLeafColumns().length}
                                className="h-24 text-center"
                            >
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {table.getState().pagination && (
                <Pagination className="justify-start mt-10">
                    <ReactPaginate
                        forcePage={table.getState().pagination.pageIndex - 1}
                        containerClassName="flex flex-row items-center"
                        disableInitialCallback
                        disabledClassName="opacity-50 pointer-events-none"
                        breakLabel={<PaginationEllipsis />}
                        nextLabel={<ChevronRight className="h-4 w-4" />}
                        nextLinkClassName="inline-flex items-center justify-center whitespace-nowrap rounded-lg transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 bg-none hover:text-primary text-sm w-10 h-10"
                        hrefBuilder={(page) => `?page=${page}`}
                        pageLinkClassName="inline-flex items-center justify-center whitespace-nowrap rounded-lg transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 bg-none hover:text-primary text-sm min-w-10 h-10 border-none"
                        activeLinkClassName="bg-primary text-primary-foreground hover:text-primary-foreground"
                        onPageChange={({ selected }) => {
                            table.setPageIndex(selected + 1);
                        }}
                        pageRangeDisplayed={3}
                        pageCount={table.getPageCount()}
                        previousLabel={<ChevronLeft className="h-4 w-4" />}
                        previousLinkClassName="inline-flex items-center justify-center whitespace-nowrap rounded-lg transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 bg-none hover:text-primary text-sm w-10 h-10"
                        renderOnZeroPageCount={null}
                    />
                </Pagination>
            )}
        </div>
    );
}
