import i18next from "i18next";
import { Languages } from "lucide-react";
import { showTranslations } from "translation-check";

import { Button } from "@/shared/ui";

const isDev = import.meta.env.DEV;

export const TranslationCheck = () => {
    if (!isDev) return null;

    const handleClick = () => {
        showTranslations(i18next);
    };

    return (
        <Button
            size="icon"
            onClick={handleClick}
            className="fixed right-4 bottom-4"
        >
            <Languages />
        </Button>
    );
};
