import { pt, ru } from "date-fns/locale";
import { Calendar } from "lucide-react";
import { forwardRef } from "react";
import RDatePicker, { DatePickerProps, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import { Input } from "@/shared/ui";

// Сюда надо добавлять все необходимые локализации
registerLocale("ru", ru);
registerLocale("pt", pt);

export const DatePicker = forwardRef<RDatePicker, DatePickerProps>(
    ({ value, ...props }, ref) => {
        const { i18n } = useTranslation();
        const selected = value ? new Date(value) : undefined;

        return (
            <RDatePicker
                ref={ref}
                selected={selected}
                customInput={
                    <Input
                        icon={<Calendar className="w-4 h-4 text-primary" />}
                    />
                }
                placeholderText="Выберите дату"
                dateFormat="Pp"
                locale={i18n.language}
                wrapperClassName="w-full"
                popperClassName="z-[500]"
                {...props}
            />
        );
    },
);

DatePicker.displayName = "DatePicker";
