import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import en from "zod-i18n-map/locales/en/zod.json";
import pt from "zod-i18n-map/locales/pt/zod.json";
import ru from "zod-i18n-map/locales/ru/zod.json";

void i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(i18nextPlugin)
    .init<HttpBackendOptions>({
        ns: ["common", "glossary"],
        defaultNS: "common",
        fallbackLng: import.meta.env.VITE_APP_DEFAULT_LOCALE,

        detection: {
            order: ["querystring", "cookie"],
            caches: ["cookie"],
        },

        interpolation: {
            escapeValue: false,
        },

        partialBundledLanguages: true,
    });

i18n.addResourceBundle("en", "zod", en);
i18n.addResourceBundle("ru", "zod", ru);
i18n.addResourceBundle("pt", "zod", pt);

z.setErrorMap(zodI18nMap);

export { z };
