import { ServerCrash } from "lucide-react";
import { useTranslation } from "react-i18next";

export const ErrorBlock = ({ error }: { error?: string }) => {
    const { t } = useTranslation();

    return (
        <div className="text-muted-foreground font-semibold p-4 text-center bg-muted rounded-lg flex flex-col items-center justify-center">
            <ServerCrash className="mb-2" />
            {error ?? t("error.fetch_long")}
        </div>
    );
};
