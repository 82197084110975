import { EventScreen } from "@/features/event";

import { Dialog, DialogContent } from "@/shared/ui";

import { IEventModal } from "../model";

export const EventModal: React.FC<IEventModal> = ({ id, tab, toggleOpen }) => {
    if (!id) return null;

    const onOpenChange = () => {
        toggleOpen?.({ id: undefined, tab: undefined });
    };

    return (
        <Dialog open={!!id} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-[1120px] p-10">
                <EventScreen eventId={id} defaultTab={tab} />
            </DialogContent>
        </Dialog>
    );
};
