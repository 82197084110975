import { useState } from "react";

interface EventVideoProps {
    data: {
        channel: number;
        url: string;
    }[];
}

export const EventVideo: React.FC<EventVideoProps> = ({ data }) => {
    const [activeVideo, setActiveVideo] = useState<{
        channel: number;
        url: string;
    }>(data[0]);

    return (
        <div>
            <video
                id="event-video"
                controls
                autoPlay
                muted
                className="w-full rounded-2xl mb-4"
            >
                <source src={activeVideo.url} type="video/mp4" />
                Your browser doesn&apos;t support HTML5 video tag.
            </video>
            <div className="grid grid-cols-4 gap-2">
                {data.map(({ channel, url }) => (
                    <video
                        key={channel}
                        id={channel.toString()}
                        controls
                        muted
                        className="rounded-2xl cursor-pointer hover:shadow-2xl"
                        onClick={() =>
                            setActiveVideo({
                                channel,
                                url,
                            })
                        }
                    >
                        <source src={url} type="video/mp4" />
                    </video>
                ))}
            </div>
        </div>
    );
};
