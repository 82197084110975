import { debounce } from "lodash";
import { BaseSelectRef, SelectProps } from "rc-select";
import { forwardRef, useMemo } from "react";

import {
    GetSuggestionApiArg,
    useLazyGetSuggestionQuery,
} from "@/shared/api/general.api";
import { WrappedSelect } from "@/shared/ui";

type Value = string | string[] | undefined;

interface SuggestionProps extends SelectProps {
    name?: GetSuggestionApiArg["name"];
    value?: Value;
    clientId?: number;
}

interface Option {
    label: string;
    value: string;
}

// TODO: нужно вернуть когда реишиться вопрос с suggestions
// const getInitialData = (value: Value): string[] => {
//     if (!value) return [];
//     if (Array.isArray(value)) return value;
//     return [value];
// };

export const Suggestion: React.FC<SuggestionProps> = forwardRef<
    BaseSelectRef,
    SuggestionProps
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
>((props, _ref) => {
    const [getSuggestion, { data = [], isFetching }] =
        useLazyGetSuggestionQuery();

    const options: Option[] = useMemo(
        () => data?.map(({ id, text }) => ({ label: text, value: id })) ?? [],
        [data],
    );

    const onSearch = debounce(async (value: string) => {
        if (props.name && value) {
            await getSuggestion({
                clientId: props?.clientId,
                // @ts-ignore
                name: props?.name?.split(".").pop(),
                term: value,
            });
        }
    }, 500);

    return (
        <WrappedSelect
            loading={isFetching}
            onSearch={onSearch}
            filterOption={false}
            options={options}
            {...props}
        />
    );
});

Suggestion.displayName = "Suggestion";
