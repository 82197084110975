import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

import { cn } from "@/shared/lib/utils";

interface LoadingProps {
    className?: string;
    text?: string;
}

export const Loading: React.FC<LoadingProps> = ({ className, text }) => {
    const { t } = useTranslation();

    return (
        <div
            className={cn(
                "flex space-x-2 h-dvh w-full items-center justify-center text-primary",
                className,
            )}
        >
            <Loader2 className="animate-spin" />
            <span>{text ?? t("loading")}</span>
        </div>
    );
};
