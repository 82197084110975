interface Language {
    name: string;
    shortName: string;
    value: string;
    emoji: string;
}

export const LANGUAGES: Language[] = [
    {
        name: "English",
        shortName: "Eng",
        value: "en",
        emoji: "🇬🇧",
    },
    {
        name: "Русский",
        shortName: "Рус",
        value: "ru",
        emoji: "🇷🇺",
    },
    {
        name: "Português",
        shortName: "Port",
        value: "pt",
        emoji: "🇵🇹",
    },
];

export const enum Route {
    App = "/",
    NotFound = "*",
    Login = "/login",
    ForgotPassword = "/login/forgot-password",
    ResetPassword = "/login/reset-password/:token",
    Vehicles = "/vehicles",
    Vehicle = "/vehicles/:id",
    Devices = "/devices",
    Device = "/devices/:id",
    Events = "/events",
    Drivers = "/drivers",
    Driver = "/drivers/:id",
    Reports = "/reports",
    DeviceTimeControl = "/reports/device-time-control",
    DriverStatisticReport = "/reports/driver-statistic",
    IndividualAnalyticalReport = "/reports/individual-analytical-report",
    UserSettings = "/user/settings",
    UserReports = "/user/reports",
    UserFilters = "/user/filters",
}
