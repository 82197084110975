import { ComponentProps, ElementType, ReactNode } from "react";

import { cn } from "@/shared/lib/utils";

interface FigureProps extends ComponentProps<ElementType> {
    label: string;
    content?: ReactNode;
    as?: ElementType;
    className?: string;
}

export const Figure: React.FC<FigureProps> = ({
    label,
    content,
    as,
    className,
    ...rest
}) => {
    const Element: ElementType = as ?? "span";
    return (
        <div className="flex flex-col space-y-0.5">
            <span className="text-sm text-gray-500">{label}</span>
            <Element className={cn("font-medium", className)} {...rest}>
                {content}
            </Element>
        </div>
    );
};
