import L from "leaflet";

export const alertIcon = L.icon({
    iconUrl: "/assets/icons/alert.svg",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -32],
});

export const carIcon = L.icon({
    iconUrl: "/assets/icons/car.svg",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -32],
});
