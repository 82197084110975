import { flatten } from "lodash";

import {
    GetGlossaryApiResponse,
    useGetGlossaryQuery,
} from "@/shared/api/general.api";

type EventGroup = GetGlossaryApiResponse["event_group"][0];
type EventType = EventGroup["types"][0];

export interface EventTypeResponse {
    data: EventGroup[];
    flat: EventType[];
    getEventTypeBySlug: (slug: string) => EventType;
}

export const useEventType = (): EventTypeResponse => {
    const { data } = useGetGlossaryQuery();

    if (!data)
        return {
            data: [],
            flat: [],
            getEventTypeBySlug: () => ({
                id: -1,
                name: "",
                slug: "",
            }),
        };

    const flat = flatten(data.event_group.map(({ types }) => types));

    const getEventTypeBySlug = (slug: string) => {
        const eventType = flat.find(
            ({ slug: eventSlug }) => eventSlug === slug,
        );

        if (!eventType) {
            return {
                id: -1,
                name: "Unknown type",
                slug: "unknown-type",
            };
        }

        return eventType;
    };

    return {
        data: data.event_group,
        flat,
        getEventTypeBySlug,
    };
};
