import * as React from "react";

import { cn } from "@/shared/lib/utils";

const InputIcon = React.forwardRef<
    React.ElementRef<typeof React.Fragment>,
    React.ComponentPropsWithoutRef<typeof React.Fragment>
>(({ children, ...props }, ref) => (
    <div
        className={cn(
            "flex items-center justify-center text-muted-foreground absolute w-12 h-full right-0",
        )}
        ref={ref}
        {...props}
    >
        {children}
    </div>
));
InputIcon.displayName = "InputIcon";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    icon?: React.ReactElement;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ value, className, type, icon, ...props }, ref) => {
        return (
            <div
                className={cn(
                    "group flex relative h-12 w-full rounded-lg border border-input bg-background has-[:focus]:border-primary has-[:disabled]:cursor-not-allowed has-[:disabled]:opacity-50 overflow-hidden",
                    {
                        "!border-destructive": props["aria-invalid"],
                        "border-0 bg-transparent": type === "file",
                    },
                    className,
                )}
            >
                <input
                    ref={ref}
                    type={type}
                    value={value ?? ""}
                    className={cn(
                        "z-10 w-full h-full px-3 py-2 border-none bg-transparent focus-visible:outline-none placeholder:text-muted-foreground group-[.text-center]:text-center",
                        {
                            "pr-12": icon,
                            "[&::-webkit-calendar-picker-indicator]:hidden":
                                type === "time",
                        },
                    )}
                    {...props}
                />
                {icon && <InputIcon>{icon}</InputIcon>}
            </div>
        );
    },
);
Input.displayName = "Input";

export { Input };
