export const Status = {
    0: null,
    1: {
        name: "Не подтверждено",
        color: "text-accent",
    },
    2: {
        name: "Подтверждено",
        color: "text-success",
    },
    3: {
        name: "Отклонено",
        color: "text-destructive",
    },
};

export enum EventModalTab {
    Photo = "photo",
    Video = "video",
    Map = "map",
}

export interface EventModalProps {
    id?: number;
    tab?: EventModalTab;
}

export interface IEventModal extends EventModalProps {
    toggleOpen?: (props: EventModalProps) => void;
}
