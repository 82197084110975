import axios from "axios";
import {
    Centrifuge,
    PublicationContext,
    Subscription,
    UnauthorizedError,
} from "centrifuge";
import { useCallback, useEffect, useState } from "react";

import { UserResponse } from "@/shared/api/general.api";

const centrifugeUrl = import.meta.env.VITE_CENTRIFUGE_URL;
const centrifugeTokenUrl = import.meta.env.VITE_CENTRIFUGE_TOKEN_URL;

export const enum UserEventTypes {
    NOTIFICATION = ".user.notification",
    PROGRESS_BAR = ".user.progress-bar",
    DEVICE_REALTIME_VIDEO = ".user.device-realtime-video-event",
    GET_CONFIG_COMPLETE = ".user.get_config_complete",
    DEVICE_WEV_GUI = ".user.device-web-gui-events",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventCallback = (data: any) => void;

interface UseCentrifugeReturn {
    subscribeUserChannel: (
        user: UserResponse,
        event: UserEventTypes,
        fn: EventCallback,
    ) => Subscription | undefined;
    subscribePublicChannel: (
        channelName: string,
        event: string,
        fn: EventCallback,
    ) => Subscription | undefined;
}

export const useCentrifuge = (userChannelName: string): UseCentrifugeReturn => {
    const [connector, setConnector] = useState<Centrifuge | undefined>();
    const [userChannel, setUserChannel] = useState<Subscription | undefined>();
    const [publicChannel, setPublicChannel] = useState<
        Subscription | undefined
    >();

    const subscribeUserChannel = useCallback(
        (user: UserResponse, event: string, fn: EventCallback) => {
            if (!connector) return;

            const channel = connector.newSubscription(
                userChannelName.replace("@userId@", user.id.toString()),
            );
            channel.on("publication", (subCtx: PublicationContext) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (subCtx.data.event === event.replace(/^\./, "")) {
                    fn(subCtx.data);
                }
            });

            channel.subscribe();
            setUserChannel(channel);
            return channel;
        },
        [connector, userChannelName],
    );

    const subscribePublicChannel = useCallback(
        (channelName: string, event: string, fn: EventCallback) => {
            if (!connector) return;

            const channel = connector.newSubscription(channelName);
            channel.on("publication", (subCtx: PublicationContext) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (subCtx.data.event === event.replace(/^\./, "")) {
                    fn(subCtx.data);
                }
            });

            channel.subscribe();
            setPublicChannel(channel);
            return channel;
        },
        [connector],
    );

    useEffect(() => {
        const centrifugeConnector = new Centrifuge(centrifugeUrl, {
            getToken: async () => {
                const res = await axios<{ token: string }>(centrifugeTokenUrl);
                if (res.status !== 200) {
                    if (res.status === 403) {
                        throw new UnauthorizedError("Unauthorized");
                    }
                    throw new Error("Failed to get token");
                }
                return res.data.token;
            },
            debug: import.meta.env.DEV,
        });

        setConnector(centrifugeConnector);

        centrifugeConnector.connect();

        return () => {
            if (userChannel) userChannel.unsubscribe();
            if (publicChannel) publicChannel.unsubscribe();
            centrifugeConnector.disconnect();
        };
        // eslint-disable-next-line
    }, []);

    return {
        subscribeUserChannel,
        subscribePublicChannel,
    };
};
