import { ChevronDown, ChevronRight } from "lucide-react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { useEventType } from "@/shared/lib/hooks";
import {
    Button,
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
    Sheet,
    SheetContent,
    SheetTrigger,
    Switch,
    WrappedSelect,
} from "@/shared/ui";

interface EventTypeInputProps {
    value?: number[];
    onChange?: (value: number[]) => void;
}

export const EventTypeInput = forwardRef<HTMLDivElement, EventTypeInputProps>(
    ({ value = [], onChange }, ref) => {
        const { t } = useTranslation();
        const { data, flat } = useEventType();

        const onCheckedChange = (val: number) => () => {
            if (value) {
                value.includes(val)
                    ? onChange?.(value.filter((id) => id !== val))
                    : onChange?.([...value, val]);
            }
        };

        const onResetAll = () => {
            onChange?.([]);
        };

        const onCheckAll = () => {
            onChange?.(data.flatMap(({ types }) => types.map(({ id }) => id)));
        };

        return (
            <div ref={ref}>
                <Sheet>
                    <SheetTrigger asChild className="-mt-5">
                        <WrappedSelect
                            value={value.map((id) => +id)}
                            onChange={onCheckedChange}
                            mode="multiple"
                            showSearch
                            placeholder={t("events.select_types")}
                            options={flat}
                            fieldNames={{
                                label: "name",
                                value: "id",
                            }}
                            open={false}
                            suffixIcon={<ChevronRight className="h-4 w-4" />}
                        />
                    </SheetTrigger>
                    <SheetContent className="max-w-[500px] sm:max-w-[500px] pt-12">
                        <div className="h-full pb-24">
                            <div className="h-full overflow-y-auto pr-4">
                                {data.map(({ name, types }, index) => (
                                    <Collapsible
                                        className="group mb-4"
                                        key={index}
                                    >
                                        <CollapsibleTrigger className="bg-accent p-4 rounded-xl w-full cursor-pointer flex justify-between items-center">
                                            <span className="font-bold">
                                                {name}
                                            </span>
                                            <ChevronDown className="h-4 w-4 group-data-[state=open]:rotate-180" />
                                        </CollapsibleTrigger>
                                        <CollapsibleContent className="mb-8">
                                            {types.map(({ id, name }) => (
                                                <div
                                                    className="flex items-center my-4"
                                                    key={id}
                                                >
                                                    <Switch
                                                        id={id.toString()}
                                                        checked={value?.includes(
                                                            id,
                                                        )}
                                                        onCheckedChange={onCheckedChange(
                                                            id,
                                                        )}
                                                    />
                                                    <label
                                                        className="ml-4 cursor-pointer select-none"
                                                        htmlFor={id.toString()}
                                                    >
                                                        {name}
                                                    </label>
                                                </div>
                                            ))}
                                        </CollapsibleContent>
                                    </Collapsible>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-center space-x-2 mt-5 bg-white p-8 drop-shadow-2xl absolute bottom-0 left-0 right-0">
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onResetAll}
                                className="w-full"
                            >
                                {t("button.turn-off-all")}
                            </Button>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onCheckAll}
                                className="w-full"
                            >
                                {t("button.turn-on-all")}
                            </Button>
                        </div>
                    </SheetContent>
                </Sheet>
            </div>
        );
    },
);

EventTypeInput.displayName = "EventTypeInput";
