import { ChevronDown, Loader2, X } from "lucide-react";
import Select, { BaseSelectRef, SelectProps } from "rc-select";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import "./styles.css";

export const WrappedSelect: React.FC<SelectProps> = forwardRef<
    BaseSelectRef,
    SelectProps
>(({ loading, ...props }, ref) => {
    const { t } = useTranslation();
    return (
        <Select
            ref={ref}
            optionFilterProp="label"
            notFoundContent={t("form.select.not_found")}
            loading={loading}
            suffixIcon={
                !loading ? (
                    <ChevronDown className="h-4 w-4" />
                ) : (
                    <Loader2 className="animate-spin h-4 w-4" />
                )
            }
            clearIcon={<X className="h-4 w-4" />}
            removeIcon={<X className="h-4 w-4" />}
            maxTagTextLength={10}
            maxTagCount="responsive"
            maxTagPlaceholder={(omittedTags) => "... " + omittedTags.length}
            {...props}
        />
    );
});

WrappedSelect.displayName = "WrappedSelect";
