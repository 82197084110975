import { Slot } from "@radix-ui/react-slot";
import { PropsWithChildren } from "react";
import { useFormContext } from "react-hook-form";

import { cn } from "@/shared/lib/utils";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Switch,
} from "@/shared/ui";

export const FilterSwitch: React.FC<{
    name: string;
    label?: string;
    defaultValue?: number;
    className?: string;
    disabled?: boolean;
}> = ({ name, label, defaultValue = 0, className, disabled }) => {
    const { control } = useFormContext();
    const isInvalid = control.getFieldState(name).invalid;

    return (
        <FormField
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => (
                <FormItem className={cn("space-y-1", className)}>
                    <div
                        className={cn(
                            "flex flex-row h-12 px-3 py-1.5 items-center justify-between rounded-lg border",
                            {
                                "border-destructive": isInvalid,
                                "opacity-50": disabled,
                            },
                        )}
                    >
                        <FormLabel className="text-md text-foreground ml-1">
                            {label ?? "\u00A0"}
                        </FormLabel>
                        <FormControl>
                            <Switch
                                checked={!!field.value}
                                onCheckedChange={(checked) =>
                                    field.onChange(checked ? 1 : 0)
                                }
                                disabled={disabled}
                                className="disabled:opacity-100"
                                {...field}
                            />
                        </FormControl>
                    </div>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export const FilterField: React.FC<{
    name: string;
    label?: string;
    className?: string;
    children: React.ReactNode;
}> = ({ name, children, label, className }) => {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className={cn("w-full space-y-0", className)}>
                    <FormLabel className="mb-2 inline-block">
                        {label ?? "\u00A0"}
                    </FormLabel>
                    <FormControl>
                        <Slot {...field}>{children}</Slot>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};

export const FilterCollapse: React.FC<
    PropsWithChildren<{ open?: boolean }>
> = ({ open = true, children }) => {
    return (
        <div
            className={cn("grid grid-rows-[0fr] duration-150", {
                "grid-rows-[1fr]": open,
            })}
        >
            <div className="overflow-hidden">
                <div className="border-b border-b-border pb-10 mb-10">
                    {children}
                </div>
            </div>
        </div>
    );
};
