import { ComponentProps, ElementType, ReactNode } from "react";

import { cn } from "@/shared/lib/utils";

interface FigureProps extends ComponentProps<ElementType> {
    label: string;
    content: ReactNode;
    as?: ElementType;
    className?: string;
}

export const Figure: React.FC<FigureProps> = ({
    label,
    content,
    as,
    className,
    ...rest
}) => {
    const Element: ElementType = as ?? "span";
    return (
        <div className="flex flex-col space-y-1">
            <span className="text-xs font-semibold text-gray-500">{label}</span>
            <Element
                className={cn("text-sm font-semibold", className)}
                {...rest}
            >
                {content}
            </Element>
        </div>
    );
};
