import chroma from "chroma-js";
import L from "leaflet";

export const getColor = (speed: number) => {
    return chroma
        .scale([
            "#8069f6",
            "#352a00",
            "#2c6400",
            "#ff4d76",
            "#790ab0",
            "#14aad9",
            "#140f0c",
            "#282d52",
            "#5f5fa7",
            "#ab99fa",
            "#a66496",
        ])
        .domain([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100])
        .out("hex")(speed);
};

const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
};

export const getDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number,
) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lng2 - lng1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // Distance in km
    return R * c;
};

export interface Dataset extends L.LatLngLiteral {
    timeIso: number;
}

export const interpolate = (
    point1: L.LatLngLiteral,
    point2: L.LatLngLiteral,
    factor: number,
): L.LatLngLiteral => {
    return {
        lat: point1.lat + (point2.lat - point1.lat) * factor,
        lng: point1.lng + (point2.lng - point1.lng) * factor,
    };
};
