import { type ClassValue, clsx } from "clsx";
import { format } from "date-fns";
import { pt, ru } from "date-fns/locale";
import i18next from "i18next";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function formatDate(date: Date, formatStr = "PP pp") {
    return format(date, formatStr, {
        locale: { ru, pt }[i18next.language],
    });
}
