import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { EventDataList } from "@/features/event";

import { GetEventsApiArg, useGetEventsQuery } from "@/shared/api/general.api";
import { useFilter } from "@/shared/lib/hooks";
import { ErrorBlock, Separator } from "@/shared/ui";

import { Filter } from "./filter";

export const History: React.FC = () => {
    const { id } = useParams();
    const { filter, setFilter } = useFilter<GetEventsApiArg>({
        filter: {
            ...(id ? { device_id: [id] } : {}),
        },
    });
    const { data, isFetching, isError } = useGetEventsQuery(filter);

    const onSubmit = useCallback(
        (values: GetEventsApiArg) => {
            setFilter({ ...values, page: 1 });
        },
        [setFilter],
    );

    const onPaginationChange = useCallback(
        (page: number) => {
            setFilter({
                ...filter,
                page,
            });
        },
        [filter, setFilter],
    );

    return (
        <>
            <Filter values={filter} onSubmit={onSubmit} />
            <Separator className="my-8" />
            {isError ? (
                <ErrorBlock />
            ) : (
                <EventDataList
                    data={data}
                    isLoading={isFetching}
                    onPaginationChange={onPaginationChange}
                />
            )}
        </>
    );
};
